import { styled } from "styled-components";
import { BREAKPOINTS } from "styles/spacing";

export const Wrapper = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const BaseCard = styled.div`
  background: #121212f7;
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  max-width: 710px;
  min-height: 450px;
  overflow: hidden;
`;

export const Footer = styled.div``;

export const ContentLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ImageLogo = styled.img`
  position: absolute;
  width: 17.5rem;
  height: 2.87rem;
  margin: 3rem;
  @media (max-width: ${BREAKPOINTS.WIDTH.MD}) {
    position: static;
  }
`;
