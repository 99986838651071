import React, { useEffect, useRef, useState } from "react";
import Spin from "componentsNew/Spin";

interface LazyIframeProps {
  videoId: string;
  width?: string;
  height?: string;
}

const LazyIframe: React.FC<LazyIframeProps> = ({
  videoId,
  width = "100%",
  height = "250",
}) => {
  const iframeRef = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.25 },
    );

    if (iframeRef.current) {
      observer.observe(iframeRef.current);
    }

    return () => {
      if (iframeRef.current) {
        observer.unobserve(iframeRef.current);
      }
    };
  }, []);

  return (
    <div ref={iframeRef} style={{ width, height }}>
      {isInView ? (
        <iframe
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spin />
          </div>
        </>
      )}
    </div>
  );
};

export default LazyIframe;
