import React, { useEffect, useState } from "react";
import { IoIosInformation } from "react-icons/io";
import { FaPencilAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import { ReactComponent as CloseIcon } from "images/x.svg";
import IconCoin from "images/candcoin.svg";
import Button from "componentsNew/Button";
import Text from "componentsNew/Text";
import { useSidBar } from "hooks/useSideBar";
import { AvatarCandidateCard } from "./Avatar";
import { useCandidates } from "hooks/useCandidates";
import { useCandidateCard } from "hooks/useCandidateCard";
import * as S from "./styles";

export const CandidateCard: React.FC = () => {
  const { open } = useSidBar();
  const { isVisible, toggleVisibility } = useCandidateCard();
  const [showBalance, setShowBalance] = useState(false);
  const { userDataCandidateSelected } = useCandidates();
  const toggleBalanceVisibility = () => setShowBalance((prev) => !prev);
  const [locality, setLocality] = useState({
    country: "",
    state: "",
    city: "",
  });
  const [targetLocality, setTargetLocality] = useState({
    country: "",
    state: "",
    city: "",
  });
  const [userPlanDescription, setUserPlanDescription] = useState("");

  const candidateCoinBalance = "0,00";
  const candidateName = userDataCandidateSelected?.name;
  const candidateLocality = userDataCandidateSelected?.locality;
  const candidatePosition = userDataCandidateSelected?.position;
  const candidateParty = userDataCandidateSelected?.party_acronym;
  const candidateTargetLocality = userDataCandidateSelected?.target_locality;
  const candidateTargetPosition = userDataCandidateSelected?.target_position;
  const candidateTargetYear = userDataCandidateSelected?.target_year;

  useEffect(() => {
    setLocality(handleCandidatesLocation(candidateLocality));
    setTargetLocality(handleCandidatesLocation(candidateTargetLocality));
  }, [userDataCandidateSelected]);

  useEffect(() => {
    const planObject = localStorage.getItem("planFeatures");
    const planFeatures = planObject ? JSON.parse(planObject) : [];
    setUserPlanDescription(planFeatures?.name || "");
  }, []);

  const handleCandidatesLocation = (local: string) => {
    const targetLocality = local || "BRA.SP.Sao Paulo";
    const part = targetLocality.split(".");
    const object = {
      country: part[0],
      state: part[1],
      city: part.slice(2).join("."),
    };
    return object;
  };

  return (
    <S.Container open={open} visibleCard={isVisible}>
      {isVisible ? (
        <div className="candidate-card">
          <div>
            <S.CloseButton onClick={toggleVisibility}>
              <CloseIcon />
            </S.CloseButton>

            <S.TitleCard>informações</S.TitleCard>
            <AvatarCandidateCard />
            <Text
              className="candidate-username"
              color="white"
              size="large"
              bold
            >
              {candidateName}
            </Text>

            <Text className="candidattus-coins" size="large" color="white">
              Tokens
            </Text>
            <div className="cand-coins-value">
              <S.CoinsIcon src={IconCoin} alt="Simbolo Token" />
              {showBalance ? (
                <Text className="coins" size="xlarge" color="primary" bold>
                  {candidateCoinBalance}
                </Text>
              ) : (
                <Text className="coins" size="xlarge" color="primary" bold>
                  *****
                </Text>
              )}
              <S.EyeButton onClick={toggleBalanceVisibility}>
                {showBalance ? <FaEye /> : <FaEyeSlash />}
              </S.EyeButton>
            </div>

            <Text className="subtitle-card" size="xSmall" color="white">
              Informações Profissionais
            </Text>
            <div className="candidate-info"></div>

            <Text className="subtitle-card" size="xSmall" color="white">
              Rede Profissional
            </Text>
          </div>
        </div>
      ) : (
        <S.InfoButton onClick={toggleVisibility}>
          <IoIosInformation size="24px" />
        </S.InfoButton>
      )}
    </S.Container>
  );
};
