import React, { useEffect, useState } from "react";
import TabsCustom from "componentsNew/Tabs";
import Spin from "componentsNew/Spin";
import { UnderConstruction } from "componentsNew/UnderConstruction";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useCandidates } from "hooks/useCandidates";
import { RoomPlenary } from "pages/RoomPlenary";
import { RoomMindset } from "pages/RoomMindset";
import { RoomKnowledge } from "pages/RoomKnowledge";
import { RoomChallenge } from "pages/RoomChallenge";
import { RoomStrategies } from "pages/RoomStrategies";
import { RoomPurpose } from "pages/RoomPurpose";
import { RoomPreferences } from "pages/RoomPreferences";
import * as S from "./styles";

const labels = [
  {
    label: "Visão Geral ",
    sub_label: "Grupos Temáticos",
    key: "tab_thematic_general_thematic_groups",
    disabled: true,
  },
  {
    label: "Dashboard ",
    sub_label: "Temas x Membros",
    key: "tab_thematic_themes_vs_members",
    disabled: true,
  },
  {
    label: "Plenária ",
    sub_label: "",
    key: "tab_thematic_plenary",
  },
  {
    label: "Sala de Ativação ",
    sub_label: "Mindset",
    key: "tab_thematic_room_activation_mindset",
  },
  {
    label: "Sala de Ativação",
    sub_label: "Conhecimento",
    key: "tab_thematic_groups_activation_knowledge",
  },
  {
    label: "Sala de Ativação",
    sub_label: "Desafios",
    key: "tab_thematic_groups_activation_challenges",
  },
  {
    label: "Sala de Ativação",
    sub_label: "Estratégias",
    key: "tab_thematic_groups_activation_strategies",
  },
  {
    label: "Sala de Ativação",
    sub_label: "Propósito",
    key: "tab_thematic_groups_activation_purpose",
  },
  {
    label: "Sala de Ativação",
    sub_label: "Preferências",
    key: "tab_thematic_groups_activation_preferences",
  },
];

const content: React.ReactNode[] = [
  <UnderConstruction />,
  <UnderConstruction />,
  <RoomPlenary />,
  <RoomMindset />,
  <RoomKnowledge />,
  <RoomChallenge />,
  <RoomStrategies />,
  <RoomPurpose />,
  <RoomPreferences />,
];

export const ThematicGroups = () => {
  const [activeTab, setActiveTab] = React.useState("tab_thematic_plenary");
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now()); //Key for PBIDash

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content.map((comp, index) =>
            React.cloneElement(comp as React.ReactElement, {
              key: `${filterKey}-${index}`,
            }),
          )}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
