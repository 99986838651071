import { COLORS } from "./colors";
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from "./typography";
import { SPACING, BREAKPOINTS } from "./spacing";

const theme = {
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SPACING,
  BREAKPOINTS,
  transition: {
    default: "0.3s ease-in-out",
    fast: "0.1s ease-in-out",
  },
};

export default theme;
