import React, { useMemo, useState } from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdRemoveRedEye } from "react-icons/md";
import { IoHeartSharp } from "react-icons/io5";
import { BiSolidCommentDetail } from "react-icons/bi";
import { BiRepost } from "react-icons/bi";
import { Spin, Tooltip } from "antd";
import { TextDanger } from "componentsNew/TextDanger";
import { ICockpitCardMentions } from "componentsNew/CockpitComponents/types/cardMentionsTypes";
import useSentimentIcon, {
  SentimentType,
} from "componentsNew/CockpitComponents/hooks/useSentimentIcon";
import LazyIframe from "componentsNew/LazyIframe";
import MediaCarousel from "../MediaCarousel";
import theme from "styles/theme";
import * as S from "./styles";

const seeMention = (link: string) => {
  window.open(link, "_blank");
  return false;
};

const getBaseUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    return urlObj.origin;
  } catch (error) {
    return "";
  }
};

const getYouTubeVideoId = (url: string): string | null => {
  const regex =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const useFormattedNumbers = (number: number | null | undefined) => {
  const formattedNumber = useMemo(() => {
    if (number == null) {
      return "-";
    }
    if (number >= 1000000) {
      return (number / 1000000).toFixed(2).replace(".", ",") + "M";
    } else {
      return number.toLocaleString("de-DE");
    }
  }, [number]);

  return formattedNumber;
};

const CockpitCardMentions = ({
  author,
  message,
  dataCreate,
  iconSocial,
  color,
  fullWidth,
  pageTypeName,
  url,
  impact,
  quotesCount,
  likesCount,
  sharesCount,
  impressionsCount,
  sentiment,
  hasBorder = false,
  imageInfo,
}: ICockpitCardMentions) => {
  const [limitLine, setLimitLine] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const MAX_CHARACTERS = 250;
  const showToggle = message.length > MAX_CHARACTERS;
  const sentimentIcon = useSentimentIcon(sentiment as SentimentType, 12);

  const formattedImpressions = useFormattedNumbers(impressionsCount);
  const formattedLikes = useFormattedNumbers(likesCount);
  const formattedQuotes = useFormattedNumbers(quotesCount);
  const formattedShares = useFormattedNumbers(sharesCount);

  const baseUrl = pageTypeName === "Online News" ? getBaseUrl(url || "") : "";
  const youtubeVideoId =
    pageTypeName === "YouTube" && url ? getYouTubeVideoId(url) : null;

  const getOpacity = (metric: "impressions" | "default" | "sentiment") => {
    if (pageTypeName === "X") {
      return 1;
    }
    if (pageTypeName === "YouTube" || pageTypeName === "Online News") {
      return metric === "sentiment" ? 1 : 0.2;
    }
    if (pageTypeName === "Facebook Public" || pageTypeName === "Instagram") {
      return metric === "impressions" ? 0.2 : 1;
    }
    return 1;
  };

  return (
    <S.ContainerCard color={color} fullWidth={fullWidth} hasBorder={hasBorder}>
      <div className="mark" />
      <div className="wrapper">
        <div className="header">
          <div className="title">
            {iconSocial ? (
              <img
                src={iconSocial}
                alt="iconSocial"
                className="icon-default"
                style={{ backgroundColor: "transparent" }}
              />
            ) : (
              <div className="icon-default">
                <AiOutlineGlobal />
              </div>
            )}

            {author && pageTypeName !== "Online News" ? (
              <S.Author>
                {pageTypeName !== "YouTube" ? "@" : ""}
                {author}
              </S.Author>
            ) : (
              <S.Author>{baseUrl && `${baseUrl}`}</S.Author>
            )}

            <FaExternalLinkAlt
              color={theme.COLORS.PRIMARY}
              onClick={() => url && seeMention(url)}
              className="external-link"
            />
          </div>
        </div>

        <TextDanger
          text={message}
          maxLineText={3}
          withMaxLengthText={!limitLine}
        />

        {showToggle && (
          <S.TextMore onClick={() => setLimitLine((prev) => !prev)}>
            {limitLine ? "ver menos" : "...ver mais"}
          </S.TextMore>
        )}

        {youtubeVideoId ? (
          <S.VideoContainer>
            <LazyIframe videoId={youtubeVideoId} />
          </S.VideoContainer>
        ) : (
          imageInfo &&
          imageInfo.length > 0 && (
            <S.ImagesPost>
              <MediaCarousel
                mediaUrls={Array.isArray(imageInfo) ? imageInfo : [imageInfo]}
                author={author}
              />
            </S.ImagesPost>
          )
        )}

        <div className="status-report">
          <S.PostReportContainer>
            <Tooltip title="Visualizações">
              <S.PostReport opacity={getOpacity("impressions")}>
                <MdRemoveRedEye color="#FFC000" size={12} />
                {getOpacity("impressions") === 1 ? formattedImpressions : "-"}
              </S.PostReport>
            </Tooltip>
            <Tooltip title="Curtidas">
              <S.PostReport opacity={getOpacity("default")}>
                <IoHeartSharp color="#FFC000" size={12} />
                {getOpacity("default") === 1 ? formattedLikes : "-"}
              </S.PostReport>
            </Tooltip>
            <Tooltip title="Comentários">
              <S.PostReport opacity={getOpacity("default")}>
                <BiSolidCommentDetail color="#FFC000" size={12} />
                {getOpacity("default") === 1 ? formattedQuotes : "-"}
              </S.PostReport>
            </Tooltip>
            <Tooltip title="Reposts">
              <S.PostReport opacity={getOpacity("default")}>
                <BiRepost color="#FFC000" size={14} />
                {getOpacity("default") === 1 ? formattedShares : "-"}
              </S.PostReport>
            </Tooltip>
            <Tooltip title="Sentimento da menção">
              <S.PostReport opacity={getOpacity("sentiment")}>
                {sentimentIcon}
              </S.PostReport>
            </Tooltip>
          </S.PostReportContainer>
          <span className="posted-on">Postado {dataCreate}</span>
        </div>
      </div>
    </S.ContainerCard>
  );
};

export default CockpitCardMentions;
