import React, { useState } from "react";
import Button from "componentsNew/Button";
import Text from "componentsNew/Text";
import MeetingContent from "componentsNew/ZoomMeeting/components/meeting-content/MeetingContent";
import { useUser } from "global/UserContext";
import { meetingRooms } from "utils/meetingRooms";
import * as S from "./styles";

const { meeting, leaveUrl } = meetingRooms.preferencesRoom;

export const RoomPreferences = () => {
  const { user } = useUser();
  const userName = user?.person?.name || "";
  const userEmail = user?.person?.email || "";

  const [isActiveCall, setIsActiveCall] = useState(false);

  const enterRoom = () => {
    console.log("Entering the meeting...");
    setIsActiveCall(true);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.OptionsContent>
          {!isActiveCall && (
            <>
              <Text size="medium" bold>
                SALA ESTRATÉGIAS
              </Text>
              <Button
                type="submit"
                title="Entrar na Reunião"
                size="medium"
                variant="primary"
                fullWidth
                onClick={enterRoom}
              >
                Entrar na Reunião
              </Button>
            </>
          )}
        </S.OptionsContent>

        {isActiveCall && (
          <MeetingContent
            meetingNumber={meeting.split("/j/")[1].split("?")[0]}
            role={0}
            sdkKey="PbqqgcIYTwSD4MPA6dXNpA"
            sdkSecret="lCSNtXdOlOrkpEoKvahoNq2GBjWeqeq2"
            passWord={meeting.split("pwd=")[1].split(".")[0]}
            userName={userName}
            userEmail={userEmail}
            leaveUrl={leaveUrl}
          />
        )}
      </S.Content>
    </S.Wrapper>
  );
};
