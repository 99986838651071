import styled from "styled-components";
import theme from "styles/theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 38px;
`;

export const Wrapper = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 1px solid ${theme.COLORS.PRIMARY};
  position: relative;

  .icon-archetype {
    max-width: 16px;
  }
`;

export const AvatarImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Icon = styled.span`
  position: absolute;
  right: -25px;
  bottom: -21px;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: ${theme.COLORS.PRIMARY};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
  }

  img {
    width: 32px;
    height: 32px;
  }
`;

export const Logo = styled.span`
  img {
    position: absolute;
    right: -12px;
    top: 72px;
    width: 4.5rem;
    z-index: 10;
  }
`;
