export const FONT_FAMILY = {
  PRIMARY: `'Roboto', sans-serif`,
  SECONDARY: `'Poppins', sans-serif`,
};

export const FONT_SIZE = {
  SMALL: "0.75rem",
  MEDIUM: "1rem",
  LARGE: "1.25rem",
  XL: "1.5rem",
  XXL: "2rem",
  X_SMALL: "1rem",
  X_LARGE: "2rem",
};

export const FONT_WEIGHT = {
  REGULAR: 400,
  MEDIUM: 600,
  BOLD: 700,
  BLACK: 900,
};
