/* eslint-disable no-nested-ternary */
import styled, { keyframes, css } from "styled-components";
import theme from "styles/theme";

interface SpinWrapperProps {
  spinning: boolean;
  size: "small" | "default" | "large";
  tip?: string;
  delay?: number;
  fullscreen?: boolean;
  hasIcon: boolean;
}

const spinAnimation = keyframes`
  to { transform: rotate(360deg); }
`;

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const spinningModifier = (size: "small" | "default" | "large") => css`
  position: relative;
  font-size: ${size === "large" ? "32px" : size === "small" ? "14px" : "16px"};
  text-align: center;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${size === "large" ? "32px" : size === "small" ? "14px" : "16px"};
    height: ${size === "large" ? "32px" : size === "small" ? "14px" : "16px"};
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top: 2px solid ${theme.COLORS.PRIMARY};
    animation: ${spinAnimation} 1s linear infinite;
  }
`;

const delayModifier = (delay: number) => css`
  ${Icon} {
    svg {
      animation-duration: ${delay}ms;
    }
  }
`;

const tipModifier = (tip: string) => css`
  &::after {
    content: attr(data-tip);
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.COLORS.PRIMARY};
    font-size: 12px;
    opacity: 0.8;
    animation: ${fadeInAnimation} 0.3s linear;
  }
`;

const fullscreenModifier = (hasIcon: boolean) => css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    margin-top: -24px;
    margin-left: -24px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top: 6px solid ${theme.COLORS.PRIMARY};
    animation: ${spinAnimation} 1s linear infinite;

    display: ${!!hasIcon && "none"};
  }
`;

export const Icon = styled.div`
  font-size: 24px;
  color: ${theme.COLORS.PRIMARY};
  svg {
    animation: ${spinAnimation} 1s linear infinite;
  }
`;

export const SpinWrapper = styled.div<SpinWrapperProps>`
  ${({ spinning, size, delay, tip, fullscreen, hasIcon }) => css`
    display: inline-block;

    ${spinning && !hasIcon && spinningModifier(size)}

    ${delay && delayModifier(delay)}

    ${tip && tipModifier(tip)}

    ${fullscreen && fullscreenModifier(hasIcon)}

    ${Icon} {
      display: ${spinning ? "block" : "none"};
    }
  `}
`;
