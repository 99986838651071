import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { Tooltip } from "antd";
import { ReactComponent as IconEye } from "assets/helper/eye.svg";
import { ReactComponent as IconWand } from "assets/helper/wand.svg";
import { ReactComponent as IconCart } from "assets/helper/cart.svg";
import { ReactComponent as IconCog } from "assets/helper/cog.svg";
import { translateText, translateValue } from "../utils/formatText";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useCandidateDiagnosticFilterContext } from "global/CandidateDiagnosticFilterContext";
import { useDrawerState } from "global/DrawerContext";
import theme from "styles/theme";
import * as S from "./styles";

interface RangeDate {
  end_date?: string;
  initial_date?: string;
}

interface MentionData {
  topic?: string;
  sentiment?: "positive" | "neutral" | "negative" | "all";
  rangeDate?: RangeDate;
  candidate?: string;
  city?: string;
  topic_sub?: string[];
  source?: string;
  theme?: string;
}

interface FloatMenuData {
  data: MentionData | null;
  activeFilter: boolean;
}

const FloatContainer = styled.div<{
  activeFilter?: boolean;
  translateXDrawerFilters: string;
}>`
  bottom: 35%;
  right: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
  transition: all 0.3s ease;
  transform: ${({ translateXDrawerFilters }) =>
    `translateX(-${translateXDrawerFilters})`};
`;

const StyledButton = styled.button`
  all: unset;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${theme.COLORS.BACKGROUND_SECONDARY};
  transition:
    width 0.3s,
    border-radius 0s 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  color: ${theme.COLORS.WHITE_3};
  font-size: 12px;

  .icon {
    width: 18px;
    height: 18px;
  }

  .content {
    display: none;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s,
      visibility 0.3s linear 0.3s;
  }

  &:hover {
    width: 150px;
    border-radius: 8px;
    transition:
      width 0.3s,
      border-radius 0s 0s;

    .icon {
      margin-right: 4px;
    }

    .content {
      display: block;
      opacity: 1;
      visibility: visible;
      transition:
        opacity 0.3s,
        visibility 0s linear 0s;
    }
  }
`;

const FORMAT = "DD/MM/YYYY";

const renderFilterItems = (data: MentionData) => {
  const filterEntries = Object.entries(data).filter(([_, value]) => value);

  return filterEntries.map(([key, value]) => {
    if (key === "candidate" && typeof value === "object" && value !== null) {
      return (
        <>
          {value?.name && (
            <S.ItemList key={`${key}-candidate`}>
              Candidato: <p>{value?.name}</p>
            </S.ItemList>
          )}
        </>
      );
    }
    if (key === "subtopics" && Array.isArray(value) && value.length > 0) {
      return (
        <S.ItemList key={key} className="type_list">
          <S.KeyText>{translateText(key)}:</S.KeyText>
          <S.ListTextBox>
            {value.map((item) => (
              <p key={`${item?.id}_value-list`}>
                {translateValue(item?.name)},{" "}
              </p>
            ))}
          </S.ListTextBox>
        </S.ItemList>
      );
    }
    if (Array.isArray(value)) {
      return (
        value?.length > 0 && (
          <S.ItemList key={key} className="type_list">
            <S.KeyText>{translateText(key)}:</S.KeyText>
            <S.ListTextBox>
              {value.map((item) => (
                <p key={`${item}_value-list`}>{translateValue(item)}, </p>
              ))}
            </S.ListTextBox>
          </S.ItemList>
        )
      );
    }
    if (
      typeof value === "object" &&
      value !== null &&
      ("initial_date" in value || "end_date" in value)
    ) {
      return (
        <>
          {value.initial_date && (
            <S.ItemList key={`${key}-initial_date`}>
              Data de início: <p>{dayjs(value.initial_date).format(FORMAT)}</p>
            </S.ItemList>
          )}
          <S.ItemList key={`${key}-end_date`}>
            Data final:{" "}
            <p>
              {value.end_date ? dayjs(value.end_date).format(FORMAT) : "Hoje"}
            </p>
          </S.ItemList>
        </>
      );
    }
    return (
      <S.ItemList key={key}>
        {translateText(key)}: <p>{translateValue(value)}</p>
      </S.ItemList>
    );
  });
};

export const FloatButtons = ({ data, activeFilter = false }: FloatMenuData) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { filters } = useDiagnosisFilter();
  const { defaultFilters } = useCandidateDiagnosticFilterContext();
  const { translateXDrawerFilters } = useDrawerState();
  const floatDefaultFilter = filters.city.length > 0 ? filters : defaultFilters;
  const isDiagnosisPages =
    location.pathname === "/dashboard/portfolio/party-diagnosis" ||
    location.pathname === "/dashboard/political-intelligence/voter-diagnosis" ||
    location.pathname ===
      "/dashboard/political-intelligence/election-diagnosis";

  const ContentData = () => {
    const filterContent = data ? renderFilterItems(data) : null;
    const diagnosisFilterContent = renderFilterItems(
      floatDefaultFilter as unknown as MentionData,
    );

    return (
      <S.FilterList>
        {filterContent}
        {isDiagnosisPages && diagnosisFilterContent}
      </S.FilterList>
    );
  };

  const buttons = [
    {
      key: 1,
      name: "Wizard Dados",
      icon: IconWand,
      path: "/dashboard/electoral-profile/wizard",
    },
    {
      key: 2,
      name: "Filtros selecionados",
      icon: IconEye,
      content: (
        <S.ContentFilters>
          <ContentData />
        </S.ContentFilters>
      ),
    },
    {
      key: 3,
      name: "Aquisições",
      icon: IconCart,
      path: "/dashboard/acquisitions/signature",
    },
    {
      key: 4,
      name: "Configurações",
      icon: IconCog,
      path: "/dashboard/settings/profile",
    },
  ];

  return (
    <>
      <FloatContainer
        activeFilter={activeFilter}
        translateXDrawerFilters={translateXDrawerFilters}
      >
        {buttons.map(({ key, name, path, icon: Icon, content }) => {
          if (content) {
            return (
              <Tooltip
                key={key}
                title={content || name}
                placement="left"
                color="#111111"
              >
                <StyledButton onClick={() => path && navigate(path)}>
                  <Icon className="icon" />
                  <span className="content">{name}</span>
                </StyledButton>
              </Tooltip>
            );
          }

          return (
            <StyledButton onClick={() => path && navigate(path)} key={key}>
              <Icon className="icon" />
              <span className="content">{name}</span>
            </StyledButton>
          );
        })}
      </FloatContainer>
    </>
  );
};
