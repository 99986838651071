import { ReactComponent as IconDiagnosis } from "assets/helper/icon-diagnosis.svg";
import { ReactComponent as IconVox } from "assets/helper/icon-vox-application.svg";
import { ReactComponent as IconWizard } from "assets/helper/icon-electoral-wizard.svg";
import { ReactComponent as IconNotepad } from "assets/helper/notepad.svg";
import { ReactComponent as IconOnboarding } from "assets/helper/icon-onboarding.svg";
import { ReactComponent as IconSocialMediaCockpit } from "assets/helper/icon-social-media-cockpit.svg";

import { enableDiagnosis } from "./tabsEnables";
import { TabContextKeys } from "./activeTabContext";

export const getMapSidebarItems = (
  activeTabContext: TabContextKeys,
  locationPathname: string,
) => {
  const isDiagnosisPages =
    locationPathname === "/dashboard/portfolio/party-diagnosis" ||
    locationPathname === "/dashboard/political-intelligence/voter-diagnosis" ||
    locationPathname === "/dashboard/political-intelligence/election-diagnosis";

  const isVoxSearch =
    locationPathname ===
      "/dashboard/political-intelligence/political-vox-search" ||
    locationPathname === "/dashboard/political-vox";

  return [
    {
      name: "Redes Sociais Cockpit",
      icon: IconSocialMediaCockpit,
      filter: false,
      path: "/dashboard/main",
      enabled: true,
    },
    {
      name: "Diagnóstico",
      icon: IconDiagnosis,
      filter: true,
      path: null,
      enabled:
        enableDiagnosis[activeTabContext as keyof typeof enableDiagnosis] &&
        isDiagnosisPages,
    },
    {
      name: "Politica Vox® Pesquisa",
      icon: IconVox,
      filter: true,
      path: null,
      enabled: isVoxSearch,
    },
    {
      name: "Politica Vox® Candidatura",
      icon: IconVox,
      filter: true,
      path: "/dashboard/political-intelligence/political-listening",
      enabled: true,
    },
    {
      name: "Wizard Dados",
      icon: IconWizard,
      filter: false,
      enabled: true,
    },
    {
      name: "Bloco de notas",
      icon: IconNotepad,
      filter: false,
      enabled: true,
    },
    {
      name: "Onboarding",
      icon: IconOnboarding,
      filter: false,
      path: false,
      enabled: true,
    },
  ];
};
