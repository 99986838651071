import styled, { css, DefaultTheme } from "styled-components";
import { ButtonProps } from ".";
import theme from "styles/theme";

export type WrapperProps = {
  hasIcon: boolean;
  darkBg: boolean;
} & Pick<ButtonProps, "size" | "fullWidth" | "variant">;

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    font-size: ${theme.FONT_SIZE.SMALL};
  `,

  medium: (theme: DefaultTheme) => css`
    font-size: ${theme.FONT_SIZE.X_SMALL};
    padding: ${theme.SPACING.X_SMALL} ${theme.SPACING.MEDIUM};
  `,

  large: (theme: DefaultTheme) => css`
    font-size: ${theme.FONT_SIZE.MEDIUM};
    padding: ${theme.SPACING.X_SMALL} ${theme.SPACING.X_LARGE};
  `,

  fullWidth: () => css`
    width: 100%;
  `,

  withIcon: (theme: DefaultTheme) => css`
    svg {
      width: 1.5rem;

      & + span {
        margin-left: ${theme.SPACING.XX_SMALL};
      }
    }
  `,

  outline: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.COLORS.PRIMARY};
    background: transparent;
    color: ${theme.COLORS.PRIMARY};
  `,

  minimal: () => css`
    border: none;
    background: transparent;
    color: ${theme.COLORS.PRIMARY};
    span {
      position: relative;
      text-decoration: none;
      color: ${theme.COLORS.PRIMARY};

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        background-color: ${theme.COLORS.PRIMARY};
        transition: width 0.3s ease;
      }

      &::before {
        left: 50%;
        transform: translateX(-50%);
      }

      &::after {
        right: 50%;
        transform: translateX(50%);
      }

      &:hover::before,
      &:hover::after {
        width: 100%;
      }
    }
  `,

  primary: () => css`
    background: ${({ theme }) => theme.COLORS.PRIMARY};
    color: black;
  `,

  darkBg: () => css`
    background: #494949;
    color: #7b7b7b;
  `,

  red: () => css`
    color: #fff;
    background: #d70708;

    &:hover {
      background: #ba0607;
    }

    &:active {
      background: #a30506;
    }
  `,

  disabled: () => css`
    &:disabled {
      cursor: not-allowed;
      filter: saturate(30%);
      transform: scale(1);
      &:hover {
        transform: scale(1);
        span {
          &::before,
          &::after {
            width: 0;
          }
        }
      }
    }
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme, size, fullWidth, hasIcon, variant, disabled, darkBg }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      180deg,
      ${theme.COLORS.RED_1} 0%,
      ${theme.COLORS.RED_4} 50%
    );
    color: ${theme.COLORS.WHITE};
    font-family: ${theme.FONT_FAMILY.PRIMARY};
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    border-radius: ${theme.BORDER_RADIUS};
    padding: 2px 8px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    /* min-width: 120px; */

    &:hover {
      transform: scale(1.03);
    }
    &:active {
      transform: scale(1.01);
    }

    .icon {
      margin-right: 8px;
    }

    ${!!size && wrapperModifiers[size](theme)};
    ${!!fullWidth && wrapperModifiers.fullWidth()};
    ${!!hasIcon && wrapperModifiers.withIcon(theme)};
    ${!!variant && wrapperModifiers[variant](theme)};
    ${disabled && wrapperModifiers.disabled()};
    ${darkBg && wrapperModifiers.darkBg()};
  `}
`;
