import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCandidates } from "hooks/useCandidates";
import { useUser } from "global/UserContext";
import {
  api,
  apiOrchestrator,
  apiPortifolio,
  apiUser,
  defaultsHeadersAxios,
  defaultsHeadersAxiosUser,
} from "services/apiService";
import { setItemStorage } from "utils/persistenceUtils";
import { AUTH_TOKEN } from "utils/constants";
import { useToast } from "hooks/useToast";
import { toast } from "react-toastify";

interface LoginState {
  username: string;
  password: string;
}

const useLogin = () => {
  const [dataValue, setValue] = useState<LoginState>({
    username: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    general: "",
  });

  const { getCandidatesById } = useCandidates();
  const { setUser, user } = useUser();

  const history = useNavigate();
  const { toastSuccess, toastError } = useToast();

  const validateEmail = (username: string) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(username);
  };

  const validatePassword = (password: string) => {
    return password && password.length > 0;
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setValue({ ...dataValue, [id]: value });
    if (id === "username" && !validateEmail(value)) {
      setErrors({ ...errors, username: "Email inválido.", general: "" });
    } else if (id === "password" && !validatePassword(value)) {
      setErrors({ ...errors, password: "Senha inválida.", general: "" });
    } else {
      setErrors({ ...errors, [id]: "", general: "" });
    }
    setRememberMe(rememberMe);
  };

  const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const fetchLogin = async () => {
    setLoading(true);
    if (
      !validateEmail(dataValue.username) ||
      !validatePassword(dataValue.password)
    ) {
      setErrors({ ...errors, general: "Email ou senha inválidos." });
      setLoading(false);
      return;
    }
    toast.info("Validando informações de autenticação...", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    try {
      const { data } = await api.post("/user/login/", { ...dataValue });
      const { token } = data;
      setItemStorage(AUTH_TOKEN, token);
      defaultsHeadersAxios(token);
      defaultsHeadersAxiosUser(token);
      const userData = await apiUser.get("/user/me");
      const cand_id = userData?.data?.person?.id_cand;
      const { data: apiImage } = await apiPortifolio.get(
        `/candidate-portfolio/candidate/${cand_id}/image`,
      );

      try {
        const planData = await apiOrchestrator.get(
          `/orchestrator-policy-role/v1/plan/owner_id/${userData.data?.person?.plan}`,
        );
        localStorage.setItem(
          "planFeatures",
          JSON.stringify({ ...planData.data }),
        );
      } catch (e) {
        console.error(e);
        localStorage.removeItem("planFeatures");
      }

      const formattedData = {
        ...userData.data,
        person: {
          ...userData?.data?.person,
          image_url: apiImage?.image_url,
        },
      };

      setUser(formattedData);

      toast.dismiss();
      toastSuccess("Login realizado com sucesso!");

      const planFeatures = JSON.parse(
        localStorage.getItem("planFeatures") || "{}",
      );
      const planName = planFeatures?.name;

      if (planName === "Plano Político") {
        history("/dashboard/political-vox");
      } else {
        history("/dashboard/main");
      }
    } catch (e) {
      toast.dismiss();
      toastError("Usuário ou senha inválidos. Por favor, tente novamente.");
      setErrors({
        ...errors,
        username: "",
        general: "Email ou senha inválidos. Por favor, tente novamente.",
      });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleForgetPassword = () => {
    history("/forgot-password");
  };

  return {
    dataValue,
    errors,
    rememberMe,
    loading,
    handleChange,
    handleRememberMeChange,
    fetchLogin,
    handleForgetPassword,
  };
};

export default useLogin;
