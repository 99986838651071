import KJUR from "jsrsasign";

export const generateZoomSignature = (
  meetingNumber: string,
  role: number,
  sdkKey: string,
  sdkSecret: string,
) => {
  const iat = Math.round(new Date().getTime() / 1000) - 30;
  const exp = iat + 60 * 60 * 2;

  const header = { alg: "HS256", typ: "JWT" };
  const payload = {
    sdkKey: sdkKey,
    mn: meetingNumber,
    role: role,
    iat: iat,
    exp: exp,
    appKey: sdkKey,
    tokenExp: iat + 60 * 60 * 2,
  };

  const sHeader = JSON.stringify(header);
  const sPayload = JSON.stringify(payload);
  const meetingSignature = KJUR.jws.JWS.sign(
    "HS256",
    sHeader,
    sPayload,
    sdkSecret,
  );

  return {
    signature: meetingSignature,
    sdkKey: sdkKey,
  };
};
