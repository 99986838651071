import React from "react";
import { CockPitProvider } from "componentsNew/CockpitComponents/hooks/useCockpit";
import Spin from "componentsNew/Spin";
import { CockpitMain } from "componentsNew/Cockpit";
import { CandidateCardProvider } from "hooks/useCandidateCard";
import { useCandidates } from "hooks/useCandidates";
import { Container } from "./styles";

export const Cockpit: React.FC = () => {
  const { isLoadedCandidates } = useCandidates();

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  return (
    <CockPitProvider>
      <CandidateCardProvider>
        <Container>
          <CockpitMain />
        </Container>
      </CandidateCardProvider>
    </CockPitProvider>
  );
};
