import React from "react";
import { motion } from "framer-motion";

import ParticlesComponent from "componentsNew/OnboardingComponents/Particles";
import { RegisterCandidateForm } from "componentsNew/RegisterComponents/Forms/RegisterCandidate";
import Logo from "assets/images/login/logo-algoricca.png";
import * as S from "./styles";
import {
  Background,
  Card,
  Container,
  Content,
  FormContainer,
  Image,
  ImageLogo,
  // SocialIconsContainer,
  StyledForm,
} from "../Login/styles";
import ImageCandidate from "assets/images/login/logo-algoricca.png";
import Text from "../../componentsNew/Text";
import SocialLogin from "../../components/SocialLogin";
import LinkedIn from "../../assets/icon-linkedin.png";
import Google from "../../assets/icon-google.png";
import Facebook from "../../assets/icon-facebook.png";
import Microsoft from "../../assets/icon-microsoft.png";
import { Input } from "../../componentsNew/NewInput";
import Checkbox from "../../componentsNew/CheckBox";
import Button from "../../componentsNew/Button";
import Spin from "../../componentsNew/Spin";

const RegisterCandidate: React.FC = () => {
  const formVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <Background>
      <Container>
        <Content>
          <div>
            <ImageLogo src={Logo} alt="Logo" />
            <Card>
              <Image src={ImageCandidate} />
            </Card>
          </div>
          <div className="form-container">
            <RegisterCandidateForm />
          </div>
        </Content>
      </Container>
    </Background>
  );
};

export default RegisterCandidate;
