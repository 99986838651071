import React, { useRef } from "react";
import ReactDOM from "react-dom";
import Text from "componentsNew/Text";
import { useSidBar } from "hooks/useSideBar";
import useOnClickOutside from "hooks/useOnClickOutside";
import ButtonSibBar from "componentsNew/ButtonSidBar";
import * as S from "./styles";

interface IModalSubMenu {
  closeSubMenu: () => void;
  subMenu: any[];
  title: string;
}

const ModalSubMenu = ({ closeSubMenu, subMenu, title }: IModalSubMenu) => {
  const { open } = useSidBar();
  const ref = useRef<HTMLHeadingElement>(null);

  useOnClickOutside(ref, () => closeSubMenu());

  return ReactDOM.createPortal(
    <>
      <S.Container open={open}>
        <div className="header">
          <Text color="primary" size="xSmall">
            {title}
          </Text>
        </div>

        {subMenu.map((menu, index) => {
          return (
            <ButtonSibBar
              label={menu.label}
              path={menu.path}
              title={menu.title}
              icon={menu.icon}
              isActive={menu.isActive}
              subMenu={menu.subMenu}
              isSubMenu
            />
          );
        })}
      </S.Container>
    </>,
    document.body,
  );
};

export default ModalSubMenu;
