import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "componentsNew/Modal";
import Text from "componentsNew/Text";

interface IModalNotAffiliations {
  closeModal: () => void;
}

const ModalNotAffiliations = ({ closeModal }: IModalNotAffiliations) => {
  const history = useNavigate();
  const handleClose = () => {
    closeModal();
    history("/logout");
  };

  return (
    <Modal
      closeModal={() => console.log()}
      onPrimaryClick={() => console.log()}
      onSecondaryClick={handleClose}
      primaryTextButton="Entrar em contato com o suporte"
      secondaryTextButton="Voltar ao Login"
      title="ATENÇÃO!"
    >
      <Text size="xSmall">
        Não há candidatos vinculados ao seu perfil. Por favor, entre em contato
        com o suporte ou o administrador do sistema para obter assistência.
      </Text>
    </Modal>
  );
};

export default ModalNotAffiliations;
