import styled from "styled-components";
import theme from "styles/theme";

export const TitleName = styled.h3`
  font-size: 12px;
  font-weight: 700;
  color: ${theme.COLORS.WHITE_3};
  text-align: center;
`;

export const ContainerButton = styled.button<any>`
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${theme.COLORS.PRIMARY};
  justify-content: space-between;
  padding: 0 6px;

  .title_name {
    text-transform: capitalize;
  }

  .wrapper-icon {
    align-items: flex-end;
    width: 20px;
  }
`;

export const ContainerDrop = styled.div`
  position: absolute;
  min-width: 320px;
  width: max-content;
  top: 100px;
  left: 7.5rem;
  border-radius: 8px;
  border: 1.5px solid ${theme.COLORS.WHITE_3};
  background: ${theme.COLORS.BACKGROUND_SECONDARY};
  overflow: scroll;
  max-height: 272px;

  .row-drop {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 36px;
    padding: 16px;
    cursor: pointer;
    text-transform: capitalize;

    &:hover {
      background: ${theme.COLORS.PRIMARY};

      p {
        color: ${theme.COLORS.BLACK};
      }
    }

    &:active {
      background: ${theme.COLORS.PRIMARY};
    }
  }
`;
