import styled from "styled-components";
import theme from "styles/theme";

export const LabelNews = styled.p`
  margin: 0;
  height: 25px;
  color: #161717 !important;
  background: ${theme.COLORS.PRIMARY};
  line-height: 25px !important;
  width: 142px;
  margin-left: -12px;
  margin-top: -1px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
`;
