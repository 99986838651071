const shades = {
  shade50: "#000000",
  shade40: "#0A0A0A",
  shade30: "#111111",
  shade20: "#161717",
  shade10: "#1D1D1D",
};

const yellows = {
  yellow30: "#302301",
  yellow20: "#FFC000",
  yellow10: "#FFC000",
};

const reds = {
  red10: "#D70708",
};

const greens = {
  green10: "#00E23F",
};

const whites = {
  white20: "#EAEAEA",
  white10: "#FFFFFF",
};

const grays = {
  gray40: "#494949",
  gray30: "#5C5C5C",
  gray20: "#7B7B7B",
  gray10: "#E9E9F0",
};

const STATUS = {
  SUCCESS: "#00E23F",
  ERROR: "#D70708",
  WARNING: "#FFC000",
  INFO: "#0288D1",
};

const WHITES = {
  WHITE_1: "#FFFFFF",
  WHITE_2: "#F5F5F5",
  WHITE_3: "#E9E9F0",
};

const BLACKS = {
  BLACK_1: "#000000",
  BLACK_2: "#181717",
  BLACK_3: "#121212",
  BLACK_4: "#161717",
  BLACK_5: "#0A0A0A",
  BLACK_6: "#111111",
  BLACK_7: "#1D1D1D",
};

const REDS = {
  RED_1: "#DD2D23",
  RED_2: "#D70708",
  RED_3: "#650202",
  RED_4: "#C70A0A",
  RED_5: "#FF0000",
};

const GREENS = {
  GREEN_1: "#00E23F",
  GREEN_2: "#419500",
  GREEN_3: "#059D4A",
  GREEN_4: "#00FF00",
};

const BLUES = {
  BLUE_1: "#2154ED",
  BLUE_2: "#0288D1",
  BLUE_3: "#223458",
  BLUE_4: "#016DBA",
  BLUE_5: "#155780",
};

const YELLOWS = {
  YELLOW_1: "#FFC000",
  YELLOW_2: "#B28801",
  YELLOW_3: "#785A00",
  YELLOW_4: "#9f7b0d",
};

const PURPLES = {
  PURPLE_1: "#9747FF",
  PURPLE_2: "#6E2F9D",
  PURPLE_3: "#9B008C",
};

const BROWNS = {
  BROWN_1: "#302301",
  BROWN_2: "#813B0C",
};

const GRAYS = {
  GRAY_10: "#EAEAEA",
  GRAY_20: "#D9D9D9",
  GRAY_30: "#A6A6A6",
  GRAY_40: "#7B7B7B",
  GRAY_50: "#5C5C5C",
  GRAY_60: "#494949",
  GRAY_70: "#373737",
  GRAY_80: "#2B2B2B",
};

const SHADES = {
  SHADE_50: "#000000",
  SHADE_40: "#0A0A0A",
  SHADE_30: "#111111",
  SHADE_20: "#161717",
  SHADE_10: "#1D1D1D",
};

const TEXT = {
  TEXT_PRIMARY: "#E9E9F0",
  TEXT_SECONDARY: "#CECECE",
  TEXT_TERTIARY: "#A6A6A6",
  TEXT_ERROR: "#D70708",
  TEXT_SUCCESS: "#00E23F",
  TEXT_WARNING: "#FFC000",
};

const BACKGROUND_COLORS = {
  BACKGROUND_MAIN: `linear-gradient(to right, #000000 50%, rgba(0,0,0,0)), linear-gradient(to right, #E3B95C, #CAAA6B, #CAA03B, #FDD274, #AA7900, #EFC569)`,
  BACKGROUND_PRIMARY: "#0A0A0A",
  BACKGROUND_SECONDARY: "#161717",
  BACKGROUND_TERTIARY: "#404040",
};

const PRIMARY_COLORS = {
  PRIMARY: "#FFC000",
  BLACK: "#000000",
  WHITE: "#FFFFFF",
};

export const COLORS = {
  ...STATUS,
  ...WHITES,
  ...BLACKS,
  ...REDS,
  ...GREENS,
  ...BLUES,
  ...YELLOWS,
  ...PURPLES,
  ...BROWNS,
  ...GRAYS,
  ...SHADES,
  ...TEXT,
  ...BACKGROUND_COLORS,
  ...PRIMARY_COLORS,

  ...shades,
  ...greens,
  ...reds,
  ...whites,
  ...yellows,
  ...grays,
};
