// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoom_container__EJljX {
  diplay: flex;
  justify-content: center;
  align-items: center;
  flex-direction: collumn;
  width: 100%;
  height: 75vh;
  margin: 16px;
  //background-color: green;
  margin-top: -200px;
}

.zoom_meetingSDKElement__setyP {
  width: 100%;
  //background-color: red;
}

.zoom_content__W0m76 {
  width: 100%;
  //background-color: blue;
}

.zoom_meetingSDKElement__setyP > div {
  align-self: center;
  justify-content: center;
  align-items: center;
  //background-color: yellow;
}
`, "",{"version":3,"sources":["webpack://./src/styles/zoom.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,0BAA0B;AAC5B","sourcesContent":[".container {\n  diplay: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: collumn;\n  width: 100%;\n  height: 75vh;\n  margin: 16px;\n  //background-color: green;\n  margin-top: -200px;\n}\n\n.meetingSDKElement {\n  width: 100%;\n  //background-color: red;\n}\n\n.content {\n  width: 100%;\n  //background-color: blue;\n}\n\n.meetingSDKElement > div {\n  align-self: center;\n  justify-content: center;\n  align-items: center;\n  //background-color: yellow;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `zoom_container__EJljX`,
	"meetingSDKElement": `zoom_meetingSDKElement__setyP`,
	"content": `zoom_content__W0m76`
};
export default ___CSS_LOADER_EXPORT___;
