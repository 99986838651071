export const meetingRooms = {
  plenaryRoom: {
    meeting:
      "https://us05web.zoom.us/j/82329130294?pwd=pyJjMGHow4OTJ2HPo5eQXT2QiVeTEM.1",
    meetingNumber: "",
    password: "",
    leaveUrl: "/dashboard/thematic-groups",
  },
  mindsetRoom: {
    meeting:
      "https://us05web.zoom.us/j/89430729632?pwd=2So4ARP8RO1q6ZK5a9i13vgL3RHHNH.1",
    meetingNumber: "",
    password: "",
    leaveUrl: "/dashboard/thematic-groups",
  },
  knowledgeRoom: {
    meeting:
      "https://us05web.zoom.us/j/87161519765?pwd=uMkUMPxbZwwGNTPJpqXHMeJVLmu7aY.1",
    meetingNumber: "",
    password: "",
    leaveUrl: "/dashboard/thematic-groups",
  },
  challengeRoom: {
    meeting:
      "https://us05web.zoom.us/j/87359803722?pwd=aEcwmpfyxTY7xZza9od54nSrBCBa6y.1",
    meetingNumber: "",
    password: "",
    leaveUrl: "/dashboard/thematic-groups",
  },
  strategiesRoom: {
    meeting:
      "https://us05web.zoom.us/j/85918856709?pwd=qua21GuHC1fHtKL6nu0q7e3skmd50A.1",
    meetingNumber: "",
    password: "",
    leaveUrl: "/dashboard/thematic-groups",
  },
  purposeRoom: {
    meeting:
      "https://us05web.zoom.us/j/89410583251?pwd=flOnHCJSo59I5GclIekhLxM5h1Ja4H.1",
    meetingNumber: "",
    password: "",
    leaveUrl: "/dashboard/thematic-groups",
  },
  preferencesRoom: {
    meeting:
      "https://us05web.zoom.us/j/87894564010?pwd=uGE7L6WMdBvSYvBY3GN5gnpOgYo34i.1",
    meetingNumber: "",
    password: "",
    leaveUrl: "/dashboard/thematic-groups",
  },
};
