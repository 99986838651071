export const SPACING = {
  XX_SMALL: "0.25rem",
  SMALL: "0.5rem",
  MEDIUM: "1rem",
  LARGE: "1.5rem",
  XL: "2rem",
  X_SMALL: "1rem",
  X_LARGE: "2rem",
};

export const BREAKPOINTS = {
  WIDTH: {
    XS: "320px",
    SM: "435px",
    MD: "768px",
    LG: "1024px",
    XL: "1920px",
  },

  HEIGHT: {
    EXTRA_SMALL: "400px",
    SMALL: "600px",
    MEDIUM: "800px",
    HIGHT: "1000px",
    EXTRA_HIGHT: "1200px",
  },
};
