import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import LogoHeader from "assets/images/logo/shield-logo-primeclub-shade.png";
import { useSidBar } from "hooks/useSideBar";
import { useMenuTitle } from "hooks/useMenuTitleContext";
import theme from "styles/theme";
import * as S from "./styles";

interface HeaderProps {
  activeSubmenu?: string;
}

const Header: React.FC<HeaderProps> = ({ activeSubmenu }) => {
  const location = useLocation();
  const { open, titleMenu } = useSidBar();
  const { menuTitle, updateMenuTitle } = useMenuTitle();

  useEffect(() => {
    let newTitle = titleMenu;

    switch (true) {
      case location.pathname.includes("/dashboard/electoral-profile/wizard"):
        newTitle = "Wizard Eleitoral";
        break;
      case location.pathname.includes("/dashboard/acquisitions/signature"):
        newTitle = "Aquisições";
        break;
      case location.pathname.includes("/dashboard/settings/profile"):
        newTitle = "Configurações";
        break;
      default:
        break;
    }

    updateMenuTitle(newTitle);
  }, [location.pathname, titleMenu, updateMenuTitle]);

  return (
    <S.HeaderContainer open={open}>
      <S.Title>
        <FontAwesomeIcon
          icon={faBell}
          style={{
            fontSize: "1.2rem",
            color: theme.COLORS.WHITE_3,
            marginRight: "1.25rem",
            marginLeft: "1.25rem",
          }}
        />
        <S.TitleText>{menuTitle}</S.TitleText>
      </S.Title>
      <S.LogoHeaderImage src={LogoHeader} alt="Logo RainMakers" />
    </S.HeaderContainer>
  );
};

export default Header;
