import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { AnimatePresence } from "framer-motion";
import { FaPlus, FaAngleDown } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsFillSuitcaseLgFill } from "react-icons/bs";
import Spin from "componentsNew/Spin";
import Text from "componentsNew/Text";
import { ReactComponent as IconVirtualTeam } from "assets/helper/virtual-team.svg";
import { ReactComponent as IconFilter } from "assets/helper/funnel.svg";
import { ReactComponent as IconCoin } from "assets/helper/icon-coin.svg";
import useOnClickOutside from "../utils/useOnClickOutsideFilter";
import { UnderConstruction } from "../UnderConstruction";
import PoliticalListening from "../PoliticalListening";
import PoliticalVox from "../PoliticalVox";
import StreamingMarkdownViewer from "../components/StreamingMarkdownViewer";
import Historic from "../components/Historic";
import MessageInput from "../components/MessageInput";
import ButtonCopilot from "../components/ButtonCopilot";
import IconButton from "../components/IconButton";
import LoadingIndicator from "../components/LoadingIndicator";
import DiagnosisFilter from "../DiagnosisFilter";
import { useReport } from "../../../global/ReportContext";
import { useUser } from "../../../global/UserContext";
import { useDiagnosisFilter } from "../../../global/DiagnosisFilterContext";
import { useTab } from "../../../global/TabContext";
import {
  mapActiveTabContext,
  TabContextKeys,
} from "./mappings/activeTabContext";
import { mapSidebarCopilot } from "./mappings/sidebarCopilot";
import { enableCopilot, enableMarketing } from "./mappings/tabsEnables";
import { getMapDefaultsButtons } from "./mappings/defaultsButtons";
import { getMapSidebarItems } from "./mappings/sidebarItems";
import { isCopilotAccessible } from "./mappings/accessCheckers";
import { buildUrl } from "./utils/buildUrl";
import { useDrawerState } from "../../../global/DrawerContext";
import { mapSidebarAdvisor } from "./mappings/sidebarAdvisor";
import { mapSidebarAdvisorPlan } from "./mappings/sidebarAdvisorPlan";
import ScheduledCalls from "microfrontends/Advisors/ScheduledCalls";
import Analysts from "microfrontends/Advisors/Analysts";
import Strategist from "microfrontends/Advisors/Strategist";
import Marketing from "microfrontends/Advisors/Marketing";
import PoliticalScientist from "microfrontends/Advisors/PoliticalScientist";
import ScheduledAdvisorCalls from "microfrontends/Advisors/ScheduledAdvisorCalls";
import TimerDisplay from "microfrontends/components/TimeDisplay";
import useMeetingDetails from "microfrontends/hooks/useMeetingDetails";
import useActiveMeetingTimer from "microfrontends/hooks/useActiveMeetingTimer";
import { usePlanType } from "pages/SettingsProfile/components";
import Cockpit from "../Cockpit";
import * as S from "./styles";
import theme from "styles/theme";
import axios from "axios";

const apiCopilot = process.env.REACT_APP_URL_COPILOT;

const useIsDiagnosisRoute = () => {
  const location = useLocation();
  return location.pathname.endsWith("/election-diagnosis");
};

interface IFilter {
  onClick: () => void;
  open: boolean;
  currentPath: string;
  onActiveFilterChange: (activeItem: any) => void;
  payloadData?: any;
}

interface IMessage {
  id?: string;
  text: string;
  content?: string;
  type: "human" | "ia";
  finished: boolean;
  title?: string;
  message_id?: string;
}

const Filter = ({
  onClick,
  open,
  currentPath = "",
  onActiveFilterChange,
  onTranslateXChange,
  payloadData,
}: IFilter & { onTranslateXChange: (value: string) => void }) => {
  const location = useLocation();
  const isDiagnosisRoute = useIsDiagnosisRoute();
  const { activeTabContext } = useTab() as {
    activeTabContext: keyof typeof enableCopilot;
  };
  const {
    isOpenMenus,
    setIsOpenMenus,
    handleOpenMenus,
    openDrawer,
    setOpenDrawer,
    openDrawerFilter,
    setOpenDrawerFilter,
    openDrawerCopilot,
    setOpenDrawerCopilot,
    openDrawerAdvisor,
    setOpenDrawerAdvisor,
    activeFilter,
    setActiveFilter,
    activePlanFeatures,
    translateXDrawerFilters,
    setTranslateXDrawerFilters,
    handleOpenDrawerFilter,
    handleOpenDrawerCopilot,
    handleOpenDrawerAdvisor,
    handleActiveFilter,
    handleActiveFilterCopilot,
    handleActiveAdvisor,
  } = useDrawerState();
  const [updatedMapSidebarCopilot, setMapSidebarCopilot] = useState<any | null>(
    null,
  );
  const [initialLoad, setInitialLoad] = useState(true);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [chatIds, setChatIds] = useState<{ [key: string]: string }>({});
  const [isFooter, setIsFooter] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef(null);
  const drawerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [filtersDiagnosis, setFiltersDiagnosis] = useState<any[]>([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [isIAResponding, setIsIAResponding] = useState(false);
  const [isStreamFinished, setIsStreamFinished] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [lastPayloads, setLastPayloads] = useState<{ [key: string]: any }>({});
  const [disabledButtons, setDisabledButtons] = useState({
    advisor: false,
    dataCuration: false,
    virtualTeam: false,
  });
  const mapDefaultsButtons = getMapDefaultsButtons();
  const { filters } = useDiagnosisFilter();
  const { reportName } = useReport();
  const { user } = useUser();
  const planType = usePlanType();

  const userIdCopilot = user?.person?.id;
  const savedFilters = JSON.parse(
    localStorage.getItem("diagnosisFilters") || "{}",
  );
  const mapSidebarItems = getMapSidebarItems(
    activeTabContext as TabContextKeys,
    location.pathname,
  );

  function isErrorWithName(error: any): error is { name: string } {
    return typeof error?.name === "string";
  }

  useEffect(() => {
    const updatedMapSidebarCopilot = mapSidebarCopilot.map((item) => ({
      ...item,
      path: item.path === "" ? location.pathname : item.path,
      disabled:
        item.type === "marketing"
          ? !enableMarketing[activeTabContext as keyof typeof enableMarketing]
          : item.disabled,
    }));
    setMapSidebarCopilot(updatedMapSidebarCopilot);
  }, [location.pathname, activeTabContext]);

  useOnClickOutside(
    drawerRef,
    () => {
      setIsOpenMenus(false);
      setOpenDrawer(false);
      setOpenDrawerFilter(false);
      setOpenDrawerCopilot(false);
      setOpenDrawerAdvisor(false);
      setActiveFilter(null);
      setTranslateXDrawerFilters("0px");
      onTranslateXChange("0px");
    },
    openDrawer,
    openDrawerFilter,
  );

  useEffect(() => {
    setMessages([]);
    setInitialLoad(true);
    if (activeFilter && activeFilter.type) {
      fetchMessagesForActiveFilter(activeFilter.type);
    }
  }, [activeFilter]);

  useEffect(() => {
    if (!activePlanFeatures) return;

    let feature = activePlanFeatures?.features_with_limits;

    if (!feature || feature.length < 1) return;

    const advisorNoExist = !feature.find(
      ({ feature }: { feature: { name: string } }) =>
        feature.name === "Advisors",
    );
    const dataCurationNoExist = !feature.find(
      ({ feature }: { feature: { name: string } }) =>
        feature.name === "Curadoria Dados",
    );
    const virtualTeamNoExist = !feature.find(
      ({ feature }: { feature: { name: string } }) =>
        feature.name === "Equipe Virtual",
    );

    setDisabledButtons({
      advisor: advisorNoExist,
      dataCuration: dataCurationNoExist,
      virtualTeam: virtualTeamNoExist,
    });
  }, [activePlanFeatures]);

  const fetchMessagesForActiveFilter = async (agentType: string) => {
    const chatId = chatIds[agentType];
    if (!chatId) return;

    setLoading(true);
    try {
      const response = await axios.get(
        `${apiCopilot}/copilot/v1/chats/${chatId}/messages?page=1&size=100`,
      );
      const fetchedMessages = response.data.items
        .map((msg: any) => ({
          ...msg,
          type: msg.type === "ai" ? "ia" : "human",
          finished: true,
        }))
        .reverse();
      setMessages(fetchedMessages);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("PAYLOAD_DATA_FILTER: ", payloadData);
  console.log("USER_ID_COPILOT: ", userIdCopilot);

  const handleSendMessage = async (
    message: string,
    agentType: string,
    payloadData: any,
    isDiagnosis = false,
  ) => {
    const newMessage: IMessage = {
      text: message,
      type: "human",
      finished: true,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setLoadingMessages(true);
    setIsIAResponding(true);
    setIsStreamFinished(false);

    const controller = new AbortController();
    setAbortController(controller);

    const payload: any = {
      user_id: userIdCopilot?.toString(),
      chat_id: chatIds[agentType] || null,
      message_id: null,
      human_message: message,
      agent: agentType,
    };

    const url = buildUrl(isDiagnosis, filters, payloadData);

    setLastPayloads((prev) => ({ ...prev, [agentType]: { ...payload } }));

    try {
      const response = await fetch(`${apiCopilot}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        signal: controller.signal,
      });

      console.log("PAYLOAD COPILOT FINAL: ", payload, url);

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      readStream(reader, agentType, payload);
    } catch (error) {
      if (isErrorWithName(error) && error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Failed to send message:", error);
      }
      setLoadingMessages(false);
      setIsIAResponding(false);
      setIsStreamFinished(true);
    }
  };

  const readStream = (
    reader: ReadableStreamDefaultReader<Uint8Array>,
    agentType: string,
    payload: any,
  ) => {
    const decoder = new TextDecoder("utf-8");
    let partialContent = "";

    const processStream = async () => {
      try {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            reader.releaseLock();
            setLoadingMessages(false);
            setIsIAResponding(false);
            setIsStreamFinished(true);
            break;
          }
          partialContent += decoder.decode(value, { stream: true });

          let boundary = partialContent.lastIndexOf("\n");
          if (boundary !== -1) {
            const messageChunk = partialContent.slice(0, boundary);
            partialContent = partialContent.slice(boundary + 1);
            try {
              const parsedMessage = JSON.parse(
                messageChunk.replace(/^data: /, ""),
              );
              setMessages((prev) => {
                const existingMessageIndex = prev.findIndex(
                  (m) => m.message_id === parsedMessage.message_id,
                );
                if (existingMessageIndex !== -1) {
                  const updatedMessages = [...prev];
                  updatedMessages[existingMessageIndex] = {
                    ...parsedMessage,
                    payload,
                  };
                  return updatedMessages;
                }
                return [...prev, { ...parsedMessage, payload }];
              });
              if (parsedMessage.chat_id && !chatIds[agentType]) {
                setChatIds((prevChatIds) => ({
                  ...prevChatIds,
                  [agentType]: parsedMessage.chat_id,
                }));
              }
            } catch (error) {
              console.error("JSON parsing error:", error);
            }
          }
        }
      } catch (error) {
        if (isErrorWithName(error) && error.name === "AbortError") {
          console.log("Stream reading aborted");
        } else {
          console.error("Error reading the stream:", error);
        }
      }
    };

    processStream();
  };

  const handleReloadMessage = async (
    messageId: string,
    payload: any,
    chatId?: string,
    isDiagnosis: boolean = false,
  ) => {
    const agentType = activeFilter?.type;
    if (!agentType) return;

    const newMessage: IMessage = {
      text: "Gerando nova resposta...",
      type: "human",
      finished: true,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setLoadingMessages(true);
    setIsIAResponding(true);

    const controller = new AbortController();
    setAbortController(controller);

    const updatedPayload = {
      ...payload,
      message_id: messageId,
      chat_id: chatId,
    };

    const url = buildUrl(isDiagnosis, filters, payloadData);

    try {
      const response = await fetch(`${apiCopilot}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedPayload),
        signal: controller.signal,
      });

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      readStream(reader, agentType, updatedPayload);
    } catch (error) {
      if (isErrorWithName(error) && error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Failed to send message:", error);
      }
      setLoadingMessages(false);
      setIsIAResponding(false);
    }

    console.log("RESEND MESSAGE:", messageId, payload);
  };

  const handleSendMessageWithAgent =
    (agentType: string, isDiagnosis: boolean = false) =>
    (message: string) => {
      handleSendMessage(message, agentType, payloadData, isDiagnosis);
      console.log("IS_DIAGNOSIS:", isDiagnosis);
    };

  const handleButtonAction = async (buttonId: string) => {
    const button = mapDefaultsButtons.find(
      (item) => item.id === buttonId && item.tab === activeTabContext,
    );
    if (button) {
      handleSendMessage(
        button.description,
        button.type,
        payloadData,
        isDiagnosisRoute,
      );
    }
  };

  const handleResumeConversation = (id: string, type: string) => {
    const filter = mapSidebarCopilot.find((item) => item.type === type);
    if (filter) {
      setActiveFilter(filter);
      setChatIds((prevChatIds) => ({ ...prevChatIds, [type]: id }));
    }
  };

  const handleNewConversation = () => {
    if (activeFilter && activeFilter.type) {
      setChatIds((prevChatIds) => ({
        ...prevChatIds,
        [activeFilter.type]: "",
      }));
      setMessages([]);
    }
  };

  const handleStop = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
    setIsIAResponding(false);
    setLoadingMessages(false);
    setIsStreamFinished(true);
  };

  console.log(
    "Mensagens enviadas ao StreamingMarkdown: ",
    messages,
    "CHAT_ID:",
    chatIds,
  );

  const getActiveTabTitles = () => {
    const activeTab = mapActiveTabContext[activeTabContext as TabContextKeys];
    if (activeTab) {
      return {
        secondLevelTitle: activeTab.second_level_title,
        thirdLevelTitle: activeTab.third_level_title,
      };
    }
    return {
      secondLevelTitle: "",
      thirdLevelTitle: "",
    };
  };

  const { secondLevelTitle, thirdLevelTitle } = getActiveTabTitles();

  const handleMenuClick = (action: () => void) => {
    action();
    handleOpenMenus();
  };

  const meetingDetails = useMeetingDetails();
  const remainingTime = useActiveMeetingTimer(meetingDetails);

  return (
    <>
      <div>
        <S.Coin
          ref={drawerRef}
          pixelsDrawer={translateXDrawerFilters}
          drawerOpen={openDrawer}
        >
          <IconCoin />
        </S.Coin>
      </div>

      <S.Timer
        ref={drawerRef}
        pixelsDrawer={translateXDrawerFilters}
        drawerOpen={openDrawer}
      >
        {remainingTime ? (
          <TimerDisplay time={remainingTime} />
        ) : (
          <TimerDisplay time={null} />
        )}
      </S.Timer>

      <S.FilterButton
        ref={drawerRef}
        onClick={() => {
          handleOpenMenus();
        }}
        className="z-index"
        pixelsDrawer={translateXDrawerFilters}
        drawerOpen={openDrawer}
      >
        <GiHamburgerMenu size="28px" color={theme.COLORS.WHITE_3} />
        <S.FilterTitle>Menu</S.FilterTitle>
      </S.FilterButton>

      <AnimatePresence>
        {isOpenMenus && !openDrawer && (
          <S.MenuContainer
            ref={menuRef}
            initial={{ opacity: 0, rotateX: -90 }}
            animate={{ opacity: 1, rotateX: 0 }}
            exit={{ opacity: 0, rotateX: -90 }}
            transition={{ duration: 0.5 }}
          >
            <S.MenuItem
              onClick={() => handleMenuClick(handleOpenDrawerFilter)}
              initial={{ opacity: 0, rotateX: -90 }}
              animate={{ opacity: 1, rotateX: 0 }}
              exit={{ opacity: 0, rotateX: -90 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <S.FilterButtonCopilot
                drawerOpen={openDrawer}
                className="z-index"
                pixelsDrawer={translateXDrawerFilters}
                disabled={disabledButtons.dataCuration}
              >
                <IconFilter />
                <S.FilterTitleCopilot disabled={disabledButtons.dataCuration}>
                  Curadoria Dados
                </S.FilterTitleCopilot>
              </S.FilterButtonCopilot>
            </S.MenuItem>
            <S.MenuItem
              onClick={() => handleMenuClick(handleOpenDrawerCopilot)}
              initial={{ opacity: 0, rotateX: -90 }}
              animate={{ opacity: 1, rotateX: 0 }}
              exit={{ opacity: 0, rotateX: -90 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <S.FilterButtonCopilot
                drawerOpen={openDrawer}
                className="z-index"
                pixelsDrawer={translateXDrawerFilters}
                isForward
                disabled={disabledButtons.virtualTeam}
              >
                <IconVirtualTeam />
                <S.FilterTitleCopilot disabled={disabledButtons.virtualTeam}>
                  Equipe Virtual
                </S.FilterTitleCopilot>
              </S.FilterButtonCopilot>
            </S.MenuItem>
            <S.MenuItem
              onClick={() => handleMenuClick(handleOpenDrawerAdvisor)}
              initial={{ opacity: 0, rotateX: -90 }}
              animate={{ opacity: 1, rotateX: 0 }}
              exit={{ opacity: 0, rotateX: -90 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <S.FilterButtonCopilot
                drawerOpen={openDrawer}
                className="z-index"
                pixelsDrawer={translateXDrawerFilters}
                disabled={disabledButtons.advisor}
              >
                <BsFillSuitcaseLgFill size="28px" />
                <S.FilterTitleCopilot disabled={disabledButtons.advisor}>
                  Advisors
                </S.FilterTitleCopilot>
              </S.FilterButtonCopilot>
            </S.MenuItem>
          </S.MenuContainer>
        )}
      </AnimatePresence>

      {openDrawer && (
        <S.DrawerMain ref={drawerRef}>
          {openDrawerAdvisor && (
            <S.HeaderMenuAdvisor>
              <BsFillSuitcaseLgFill /> Advisors
            </S.HeaderMenuAdvisor>
          )}
          <S.DrawerContainer>
            <S.SidebarContainer>
              {openDrawer &&
                openDrawerFilter &&
                mapSidebarItems.map((item) => (
                  <S.SidebarButton
                    key={item.name}
                    active={activeFilter?.name === item.name}
                    onClick={() =>
                      handleActiveFilter(item, fetchMessagesForActiveFilter)
                    }
                    disabled={
                      (!!item.path && item.path !== currentPath) ||
                      !item.enabled
                    }
                  >
                    <S.IconContainer>
                      <item.icon color="white" />
                      {item.filter && (
                        <IconFilter
                          width="10px"
                          height="10px"
                          color={theme.COLORS.PRIMARY}
                        />
                      )}
                    </S.IconContainer>
                    <S.ButtonTitle>{item.name}</S.ButtonTitle>
                  </S.SidebarButton>
                ))}

              {openDrawer &&
                openDrawerCopilot &&
                updatedMapSidebarCopilot.map((item: any) => (
                  <S.SidebarButtonCopilot
                    key={item.name}
                    active={activeFilter?.name === item.name}
                    onClick={() =>
                      handleActiveFilterCopilot(
                        item,
                        fetchMessagesForActiveFilter,
                      )
                    }
                    disabled={
                      item.disabled ||
                      !isCopilotAccessible ||
                      !enableCopilot[activeTabContext]
                    }
                  >
                    <S.IconContainer>
                      <item.icon color="white" />
                    </S.IconContainer>
                    <S.ButtonTitle>{item.name}</S.ButtonTitle>
                  </S.SidebarButtonCopilot>
                ))}

              {openDrawer &&
                openDrawerAdvisor &&
                planType === "Advisor" &&
                mapSidebarAdvisorPlan.map((item: any) => (
                  <S.SidebarButtonCopilot
                    key={item.name}
                    active={activeFilter?.name === item.name}
                    onClick={() => handleActiveAdvisor(item)}
                    disabled={item.disabled}
                  >
                    <S.IconContainer>
                      <item.icon color="white" />
                    </S.IconContainer>
                    <S.ButtonTitle>{item.name}</S.ButtonTitle>
                  </S.SidebarButtonCopilot>
                ))}

              {openDrawer &&
                openDrawerAdvisor &&
                planType !== "Advisor" &&
                mapSidebarAdvisor.map((item: any) => (
                  <S.SidebarButtonCopilot
                    key={item.name}
                    active={activeFilter?.name === item.name}
                    onClick={() => handleActiveAdvisor(item)}
                    disabled={item.disabled}
                  >
                    <S.IconContainer>
                      <item.icon color="white" />
                    </S.IconContainer>
                    <S.ButtonTitle>{item.name}</S.ButtonTitle>
                  </S.SidebarButtonCopilot>
                ))}
            </S.SidebarContainer>
          </S.DrawerContainer>

          <AnimatePresence>
            {activeFilter && openDrawer && openDrawerFilter && (
              <S.ContentFilter>
                <S.BoxContent
                  key={activeFilter.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <S.Heading>{activeFilter?.name}</S.Heading>
                  {activeFilter?.name === "Redes Sociais Cockpit" && (
                    <Cockpit />
                  )}
                  {activeFilter?.name === "Politica Vox® Candidatura" && (
                    <PoliticalListening />
                  )}
                  {activeFilter?.name === "Politica Vox® Partido" && (
                    <PoliticalVox />
                  )}
                  {activeFilter?.name === "Politica Vox® Pesquisa" && (
                    <PoliticalVox />
                  )}
                  {activeFilter?.name === "Wizard Dados" && (
                    <UnderConstruction />
                  )}
                  {activeFilter?.name === "Bloco de notas" && (
                    <UnderConstruction />
                  )}
                  {activeFilter?.name === "Onboarding" && <UnderConstruction />}

                  {activeFilter?.name === "Diagnóstico" && (
                    <DiagnosisFilter
                      reportName={reportName}
                      onFiltersChange={setFiltersDiagnosis}
                    />
                  )}
                </S.BoxContent>
              </S.ContentFilter>
            )}

            {activeFilter && openDrawer && openDrawerCopilot && (
              <S.ContentFilter isCopilot>
                <S.BoxContent
                  key={activeFilter.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <S.HeaderInternalContent>
                    <S.Heading>
                      {activeFilter?.name}{" "}
                      {activeFilter?.name !== "Histórico" &&
                        `| ${secondLevelTitle}`}
                      {activeFilter?.name !== "Histórico" && (
                        <Text color="yellow">{thirdLevelTitle}</Text>
                      )}
                    </S.Heading>
                    <S.HeaderButtonsContent>
                      {activeFilter?.name !== "Histórico" && (
                        <>
                          <Tooltip title="Novo Chat" placement="left">
                            <div>
                              <IconButton
                                onClick={handleNewConversation}
                                icon={FaPlus}
                              />
                            </div>
                          </Tooltip>
                          <IconButton
                            disabled
                            onClick={() => console.log("/* toggleFooter */")}
                            icon={FaAngleDown}
                          />
                        </>
                      )}
                    </S.HeaderButtonsContent>
                  </S.HeaderInternalContent>

                  {loading ? (
                    <Spin fullscreen />
                  ) : (
                    <>
                      <S.BoxInternalContent
                        ref={chatContainerRef}
                        isHistoric={activeFilter.name === "Histórico"}
                        isFooter={isFooter}
                      >
                        {activeFilter && activeFilter.name !== "Histórico" && (
                          <>
                            <S.TitleInternalContent>
                              {activeFilter?.title}
                            </S.TitleInternalContent>
                            <S.BoxChat>
                              <S.ChatContainer>
                                <StreamingMarkdownViewer
                                  messages={messages}
                                  externalRef={chatContainerRef}
                                  onReloadMessage={(
                                    messageId: string,
                                    payload: any,
                                    chatId?: string,
                                  ) =>
                                    handleReloadMessage(
                                      messageId,
                                      payload,
                                      chatId,
                                      activeFilter?.name === "Diagnóstico",
                                    )
                                  }
                                />

                                {loadingMessages && <LoadingIndicator />}
                              </S.ChatContainer>
                            </S.BoxChat>

                            {!isIAResponding && (
                              <S.BoxButton hidden={isIAResponding}>
                                {mapDefaultsButtons
                                  .filter(
                                    (item) =>
                                      item.tab === activeTabContext &&
                                      item.type === activeFilter.type,
                                  )
                                  .map((item) => (
                                    <ButtonCopilot
                                      key={item.id}
                                      title={item.description}
                                      onClick={() =>
                                        handleButtonAction(item.id)
                                      }
                                    />
                                  ))}
                              </S.BoxButton>
                            )}
                          </>
                        )}
                        {activeFilter && activeFilter.name === "Histórico" && (
                          <Historic
                            setActiveFilter={setActiveFilter}
                            setChatIds={setChatIds}
                            onResume={handleResumeConversation}
                          />
                        )}
                      </S.BoxInternalContent>

                      {activeFilter && activeFilter.name !== "Histórico" && (
                        <S.BoxInput>
                          <MessageInput
                            onSendMessage={handleSendMessageWithAgent(
                              activeFilter.type,
                              isDiagnosisRoute,
                            )}
                            isIAResponding={isIAResponding}
                            onStop={handleStop}
                          />
                        </S.BoxInput>
                      )}
                    </>
                  )}
                </S.BoxContent>
              </S.ContentFilter>
            )}

            {activeFilter &&
              openDrawer &&
              openDrawerAdvisor &&
              (planType === "Advisor" ? (
                <S.ContentAdvisor>
                  <S.BoxContent>
                    <Text size="medium" color="yellow" bold>
                      {activeFilter.name}
                    </Text>

                    {activeFilter.name === "Agenda" && (
                      <ScheduledAdvisorCalls />
                    )}
                  </S.BoxContent>
                </S.ContentAdvisor>
              ) : (
                <S.ContentAdvisor>
                  <S.BoxContent>
                    <Text size="medium" color="yellow" bold>
                      {activeFilter.name}
                    </Text>

                    {activeFilter.name === "Chamadas Agendadas" && (
                      <ScheduledCalls />
                    )}
                    {activeFilter.name === "Analista" && <Analysts />}
                    {activeFilter.name === "Estrategista" && <Strategist />}
                    {activeFilter.name === "Marketing" && <Marketing />}
                    {activeFilter.name === "Cientista Político" && (
                      <PoliticalScientist />
                    )}
                  </S.BoxContent>
                </S.ContentAdvisor>
              ))}
          </AnimatePresence>
        </S.DrawerMain>
      )}
    </>
  );
};

export default Filter;
