import IconCockpit from "icons/icon-cockpit.svg";
import IconCommunity from "assets/icon-community.svg";
import IconEducation from "assets/icon-education.svg";
import IconProfileCommunity from "assets/icon-profile-community.svg";
import IconIntelligenceNetworking from "assets/icon-intelligence-networking.svg";
import IconThematicGroups from "assets/icon-thematic-groups.svg";

const sibBarMenu = [
  {
    title: "Cockpit",
    label: "Cockpit",
    icon: IconCockpit,
    path: "/dashboard/main",
    subMenu: null,
    isActive: true,
  },
  {
    title: "Comunidade",
    icon: IconCommunity,
    path: null,
    isActive: true,
    subMenu: [
      {
        title: "Perfil da comunidade",
        icon: IconProfileCommunity,
        label: "Perfil da comunidade",
        path: "/dashboard/community-profile",
        isActive: true,
      },
      {
        title: "Inteligência Networking",
        icon: IconIntelligenceNetworking,
        label: "Inteligência Networking",
        path: "/dashboard/intelligence-networking",
        isActive: true,
      },
      {
        title: "Grupos Temáticos",
        icon: IconThematicGroups,
        label: "Grupos Temáticos",
        path: "/dashboard/thematic-groups",
        isActive: true,
      },
    ],
  },
  {
    title: "Educação",
    icon: IconEducation,
    path: null,
    isActive: false,
    subMenu: [],
  },
];

export default sibBarMenu;
